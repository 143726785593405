import React, { useEffect, useRef, useState } from 'react';

const Header = () => {
  const headerRef = useRef(null);
  const navBgRef = useRef(null);
  const menuIconRef = useRef(null);
  const navBarRef = useRef(null);
  
  const [menuActive, setMenuActive] = useState(false);

  // Function to handle scroll animation
  const scrollFunction = () => {
    const header = headerRef.current;
    const navbg = navBgRef.current;
    

    if (window.scrollY > 80) {
      // Apply styles when scrolled down more than 80px
      header.style.borderRadius = "50px";
      header.style.top = "5px";
      header.style.width = "calc(100% - 30px)";
      header.style.marginLeft = "15px";
      header.style.marginRight = "15px";
      header.style.backgroundColor = "rgba(224, 238, 240, 0.202)";

      navbg.style.top = `calc(${navbg.style.top} + 5px)`;
      navbg.style.width = "calc(100% - 30px)";
      navbg.style.marginLeft = "15px";
      navbg.style.marginRight = "15px";
    } else {
      // Reset styles when scrolled back to the top
      header.style.borderRadius = "0px";
      header.style.top = "0px";
      header.style.width = "100%";
      header.style.marginLeft = "0px";
      header.style.marginRight = "0px";
      header.style.backgroundColor = "transparent";

      navbg.style.top = `calc(${navbg.style.top} - 5px)`;
      navbg.style.width = "100%";
      navbg.style.marginLeft = "0px";
      navbg.style.marginRight = "0px";
    }
  };

  // Toggle mobile menu visibility
  const toggleMenu = () => {
    setMenuActive(!menuActive);  // Toggle menu state
  };

  // Adding the scroll event listener using useEffect hook
  useEffect(() => {
    window.onscroll = scrollFunction;

    return () => {
      window.onscroll = null; // Cleanup on component unmount
    };
  }, []);

  return (
    <>
        <header ref={headerRef} id="header" className={`header ${menuActive ? 'active' : ''}`}>
        <a href="/" className="logo">
            Thomas <span>Tamagni</span>
        </a>

        {/* Mobile Menu Icon */}
        <i
            ref={menuIconRef}
            className={`fa-solid ${menuActive ? 'fa-xmark' : 'fa-bars'}`}
            id="menu-icon"
            onClick={toggleMenu}
        ></i>

        {/* Navbar Links */}
        <nav ref={navBarRef} className={`navbar ${menuActive ? 'active' : ''}`}>
            <a href="#Home" onClick={toggleMenu}>Home</a>
            <a href="#About" onClick={toggleMenu}>About</a>
            <a href="#Projects" onClick={toggleMenu}>Projects</a>
            <a href="#Contacts" onClick={toggleMenu}>Contacts</a>
        </nav>

        
        </header>
        <div ref={navBgRef} className={`nav-bg ${menuActive ? 'active' : ''}`}></div>
    </>
  );
};

export default Header;
