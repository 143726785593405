import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';


function Contacts () {
    //Recaptcha value
    const [capVal, setCapVal] = useState(null);

    //Email form
    const [emailForm, setEmailForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    //Result if message was sent or not
    const [result, setResult] = useState('');

    //Status of while message is being sent
    const [status, setStatus] = useState('Submit');

    function resetEmailForm() {
        setEmailForm({ firstName: '',lastName: '', email: '', message: '' });
    }

    function handleEmailFormChange(event) {
        setEmailForm((prevEmailData) => {
            return {
                ...prevEmailData,
                [event.target.name]: event.target.value,
            };
        });

        if (result.length > 0) {
            setResult('');
        }
    }

    const handleSubmit = async (e) => {
        setResult('');
        e.preventDefault();
        setStatus('Sending...');

        const { firstName, lastName, email, message } = e.target.elements;

        let details = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            message: message.value,
        };

        try {
            let response = await fetch(process.env.REACT_APP_SERVER_URL+'/api/mails/sendGmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(details),
            });
            setStatus('Submit');
            let result = await response.json();

            if (result.status === 'success') {
                setResult('Message Sent!');
                resetEmailForm();
            } else if (result.status === 'fail') {
                alert('Uh oh! Message failed to send.');
            }
        } catch (error) {
            console.error(error);
            setStatus('Submit');
            setResult('Uh oh! Issues with submitting message.');
        }
    };



    return(
        <section id="Contacts">
    
            <div className="container">
                <h3>let's talk</h3>
                <h1>Contact Me </h1>
                <form method="POST" id="Contact_form" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="user_first_name"></label><br/>
                        <input placeholder="First Name" type="text" id="user_first_name" name="firstName"  minLength="3" maxLength="12" value={emailForm.firstName} onChange={handleEmailFormChange} required={true}/>
                    </div>
                    <div>
                        <label htmlFor="user_last_name"></label><br/>
                        <input placeholder="Last Name"type="text" name="lastName" id="user_last_name"  value={emailForm.lastName} onChange={handleEmailFormChange} required={true}/>
                    </div>
                    <div>
                        <label htmlFor="user_email"></label><br/>
                        <input placeholder="Email"type="email" name="email" id="user_email"  required={true} value={emailForm.email} onChange={handleEmailFormChange} />
                    </div>
                    <div>
                        <label htmlFor="user_message"></label><br/>
                        <textarea maxLength={300} placeholder="Type your message..." type="text" name="message" id="user_message"  required={true} value={emailForm.message} onChange={handleEmailFormChange} ></textarea>
                    </div>
                    <div className="recaptcha-container">
                        <ReCAPTCHA 
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
                            onChange={(val) => setCapVal(val)}
                        />
                    </div> 
                    <div>
                        <button disabled={!capVal} type="submit">{status}</button> 
                        <h3>{result}</h3>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contacts;